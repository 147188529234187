//
// custom-variables.scss
//

$primary-1: #ff715b;
$primary-2: #ff934f;
$primary-3: #3ccf91;
$primary-4: #3c5ccf;
$primary-5: #5132c0;
$primary-6: #8f2df0;

$gray-1: #12141d;
$gray-2: #1e2029;
$gray-3: $gray-2; //#2A2C34;
$gray-4: #e3e4e8;

$gradient-1: linear-gradient(90deg, $primary-1 0, $primary-2 100%);
$gradient-2: linear-gradient(90deg, $primary-2 0, $primary-3 100%);
$gradient-3: linear-gradient(90deg, $primary-3 0, $primary-4 100%);
$gradient-4: linear-gradient(90deg, $primary-4 0, $primary-5 100%);
$gradient-5: linear-gradient(90deg, $primary-5 0, $primary-6 100%);
$gradient-6: linear-gradient(90deg, $primary-1 0, $primary-4 100%);
$gradient-7: linear-gradient(90deg, $primary-1 0, $primary-5 100%);
$gradient-8: linear-gradient(90deg, $primary-6 0, $primary-4 100%);
$gradient-9: linear-gradient(90deg, #8f2df0 0, $primary-2 100%);

// Shadow
$shadow: none; //0 0 35px 0 rgba(49, 57, 66, 0.5);
$shadow-lg: none; //0 0 45px 0 rgba(0, 0, 0, 0.12);

//Background left-sidebar
//$bg-leftbar: linear-gradient(135deg, #8f75da 0, #727cf5 60%);
//$bg-leftbar-light: #fff;
//$bg-leftbar-dark: $gray-3;
//$bg-topnav: linear-gradient(to bottom, #8f75da, #727cf5);

// Deatched left sidenav
//$bg-detached-leftbar: $gray-2;
//
//// Leftbar width
//$leftbar-width: 220px;
//
//// Boxed Layout
//$boxed-layout-width: 1300px;
//$boxed-layout-bg: #3e4853;
//
//// Topbar Height
//$topbar-height: 70px;
//
//// Menu item colors (Default-dark)
//$menu-item: #cedce4;
//$menu-item-hover: #fff;
//$menu-item-active: #fff;
//
//// Menu item colors (light)
//$menu-item-light: #6c757d;
//$menu-item-light-hover: #727cf5;
//$menu-item-light-active: #727cf5;
//
//// Detached Menu item colors
//$menu-item-dark: #8391a2;
//$menu-item-dark-hover: #727cf5;
//$menu-item-dark-active: #727cf5;
//
//// Dark sidenav Menu item color
//$sidebar-dark-menu-item: #8391a2;
//$sidebar-dark-menu-item-hover: #bccee4;
//$sidebar-dark-menu-item-active: #fff;
//
//// Rightbar
//$rightbar-width: 280px;
//$rightbar-bg: $gray-2;
//$rightbar-title-bg: #727cf5;
//$rightbar-title-color: #fff;
//$rightbar-title-btn-bg: lighten($rightbar-title-bg, 5%);
//$rightbar-title-btn-color: #fff;
//$rightbar-overlay-bg: #000;
//
//// Topbar Background
//$bg-topbar: $gray-3;
//$bg-topbar-dark: $gray-3;
//
//// Topbar Search
//$bg-topbar-search: #464f5b;
//$bg-topbar-dark-search: #464f5b;

// Helpbox
$help-box-light-bg: rgba(255, 255, 255, 0.1);
$help-box-dark-bg: #727cf5;

// Font weight
$font-weight-semibold: 600;

// Dropdown Large
$dropdown-lg-width: 320px;

// Page title color
$page-title-color: #fff;

// Header title
$header-title-color: #fff;

// Nav-pill background
//$nav-pills-bg: #404954;

// Custom-accordion
$custom-accordion-title-color: #8391a2;

// Dragula demo background
$dragula-bg: #404954;

// Form wizard header background
$form-wizard-header-bg: #404954;

// Custom title color
$text-title-color: #fff;

// card Loader background
$card-loader-bg: #313a46;

// Chat widget
$chat-primary-user-bg: #404954;
$chat-secondary-user-bg: #404954;

// User authentication Background
$auth-bg: #404954;
$auth-bg-pattern-img: url('../../../images/bg-pattern-dark.png');

// Apex chart
$apex-grid-color: #404954;

// Hero
$hero-bg: linear-gradient(to bottom, #697ded, #5e30c1);
