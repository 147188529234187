/*
Template Name: Hyper - React Js Admin Dashboard Template
Version: 2.0.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/

//Core files
@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';
@import 'config/saas/_variables-dark';
@import 'config/saas/_custom-variables-dark';
@import './node_modules/bootstrap/scss/bootstrap';

//Fonts
//@import 'custom/fonts/nunito';

//Components
//@import 'custom/components/mixins';
//@import 'custom/components/badge';
//@import 'custom/components/buttons';
@import 'custom/components/card';
//@import 'custom/components/popover';
//@import 'custom/components/tables';
//@import 'custom/components/type';
//@import 'custom/components/utilities';

// Vendors
@import './node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min';
@import './node_modules/react-notifications-component/dist/scss/notification';
@import './node_modules/prismjs/themes/prism-tomorrow';

@import 'custom/components/syntax';

// Plugins
@import 'custom/plugins/apexcharts';

//@import 'custom/plugins/datatable';

