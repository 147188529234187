.token.function, .token.boolean, .token.number {
  color: $primary-1
}

.token.property, .token.class-name, .token.constant, .token.symbol {
  color: lighten($primary-4, 10%);
}

.token.string, .token.char, .token.attr-value, .token.regex, .token.variable {
  color: $primary-3;
}

.token.selector, .token.important, .token.atrule, .token.keyword, .token.builtin {
  color: lighten($primary-6, 20%);
}

.token.punctuation {
  color: $gray-4;
}

pre {
  color: $gray-4;
}
